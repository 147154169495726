import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  createNewProjectData,
  createProjectSettingsData,
  resetCreateProject,
  resetUpdateProject,
  updateProjectDetailsData,
} from "../actions/projectActions";
import {
  createConfigApi,
  createNewProjectApi,
  UpdateProjectApi,
  createCMSConfigApi,
  updateCMSApi,
  cmsConfigCheckApi,
} from "../apis/projectApis";

var p_id;

export function* watchCreateNewProject() {
  yield takeLatest("CREARE_NEW_PROJECT_ACTION", handleCreateNewProjects);
  //yield takeLatest('CREATE_PROJECT_SETTINGS',handleCreateConfig)
  yield takeLatest("UPDATE_PROJECT_DETAILS", handleUpdateDetails);
}

function* handleCreateNewProjects({ payload }) {
  const CheckConfig = yield call(handleCheckCMSConfig, payload.kalturaConfig);
  if (CheckConfig && CheckConfig.data && CheckConfig.data.hzStatus === 200) {
    const newProject = yield call(handleCreateProject, payload.addProject);
    p_id = newProject?.data?.p_id;
    yield put(createNewProjectData(newProject));
    const createConfig = yield call(handleCreateConfigApi, payload.configDetails);
    yield put(createProjectSettingsData(createConfig));
    yield call(handleCreateCMSConfigApi, payload.kalturaConfig);
    yield put(resetCreateProject());
  } else {
    yield put(createNewProjectData(CheckConfig));
    yield put(resetCreateProject());
  }
}

function* handleUpdateDetails({ payload }) {
  const cmsConfigCheck = yield call(handleCheckCMSConfig, payload.cmsConfig);
  if (cmsConfigCheck && cmsConfigCheck.data && cmsConfigCheck.data.hzStatus === 200) {
    const updateDetails = yield call(handleUpdateProject, payload);
    yield put(updateProjectDetailsData(updateDetails));
    const updateConfig = yield call(handleUpdateConfig, payload);
    yield put(createProjectSettingsData(updateConfig));
    yield call(handleUpdateCMS, payload);
    yield put(resetUpdateProject());
  } else {
    yield put(updateProjectDetailsData(cmsConfigCheck));
    yield resetUpdateProject();
  }
}

const handleCheckCMSConfig = async (cmsConfig) => {
  try {
    const res = await axios.post(cmsConfigCheckApi, cmsConfig, {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

const handleCreateProject = async (addProjects) => {
  try {
    const res = await axios.post(createNewProjectApi, addProjects, {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleCreateConfigApi = async (configDetails) => {
  try {
    const res = await axios.post(createConfigApi, configDetails, {
      params: { p_id: p_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleCreateCMSConfigApi = async (CmsConfig) => {
  try {
    const res = await axios.post(createCMSConfigApi, CmsConfig, {
      params: { p_id: p_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleUpdateProject = async (updateDetails) => {
  try {
    const res = await axios.put(UpdateProjectApi, updateDetails, {
      params: { p_id: updateDetails.p_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleUpdateConfig = async (updateDetails) => {
  const config = {
    bgColor: updateDetails.config.colors.bgColor,
    loaderImage: updateDetails.config?.loaderImage || {},
    seeMoreIcon: updateDetails.config?.seeMoreIcon || {},
    fgColor: updateDetails.config.colors.fgColor,
    hgColor: updateDetails.config.colors.hgColor,
    gdColor: updateDetails?.config?.colors?.gdColor || null,
    mnColor: updateDetails?.config?.colors?.mnColor || null,
    ftColor: updateDetails?.config?.colors?.ftColor || null,
    btn_gdColor: updateDetails?.config?.colors?.btn_gdColor || null,
    bgOption: updateDetails?.config?.bgOption || null,
    bgImage: updateDetails?.config?.bgImage,
    font: updateDetails.config.font,
    customFontName: updateDetails.config.customFontName,
    enabledLanguages: updateDetails.config.enabledLanguages,
    tags: updateDetails.tags,
    contentBadges:updateDetails?.config.contentBadges || []
  };

  try {
    const res = await axios.post(createConfigApi, config, {
      params: { p_id: updateDetails.p_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleUpdateCMS = async (updateDetails) => {
  try {
    const res = await axios.put(updateCMSApi, updateDetails.cmsConfig, {
      params: { p_id: updateDetails.p_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
