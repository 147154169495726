import React from 'react';
import { Box, Typography, FormControl, Select, MenuItem, Switch } from '@mui/material';

const CardSelector = ({ hybrid, setWidgetCommonDetails }) => {

  console.log(hybrid,"hybrid")
  const handleToggleChange = (event) => {
    const isEnabled = event.target.checked;

    setWidgetCommonDetails((prevDetails) => ({
      ...prevDetails,
      hybrid: {
        ...prevDetails?.hybrid,
        isEnabled: isEnabled,
        hybridName: isEnabled ? prevDetails?.hybrid?.hybridName : '', // Clear hybridName if disabled
      },
    }));
  };

  const handleSelectChange = (event) => {
    setWidgetCommonDetails((prevDetails) => ({
      ...prevDetails,
      hybrid: {
        ...prevDetails?.hybrid,
        hybridName: event.target.value,
      },
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography>Hybrid Rail</Typography>
        <Switch
          checked={hybrid?.isEnabled}
          onChange={handleToggleChange}
        />
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 180,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
          }}
        >
          <Select
            value={hybrid?.hybridName || ''}
            onChange={handleSelectChange}
            disabled={!hybrid?.isEnabled}
            displayEmpty
            sx={{
              minHeight: '40px',
              '.MuiSelect-select': {
                paddingTop: '12px',
                paddingBottom: '12px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="">
              <>Select Card</>
            </MenuItem>
            <MenuItem value="landscape">Landscape</MenuItem>
            <MenuItem value="portrait">Portrait</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CardSelector;
