import React from "react";
import { AntSwitch } from "components/Switch/AntSwitch";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import VivreAutocomplete from '../Autocomplete/Autocomplete'

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";

const useStyles = makeStyles(styles);

//Number Of Items  in the Carousel
const numberOfItem = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const CaroslType = (props) => {
  const [selectedDisplayOption, setSelectedDisplayOption] = React.useState();
  const [selectedIndicatorPosition, setSelectedIndicatorPosition] = React.useState();
  const [autoSlideDuration,setAutoSlideDuration]=React.useState()

  const classes = useStyles();

React.useEffect(()=>{
  setAutoSlideDuration(props?.caresoulWidgetdataType?.autoRotateDuration?.toString())
},[])

  React.useEffect(()=>{
    if(props?.caresoulWidgetdataType?.titlePosition==="TOP"){
      setSelectedDisplayOption(0)
    }
    else if(props?.caresoulWidgetdataType?.titlePosition==="MDL"){setSelectedDisplayOption(1)}
else if(props?.caresoulWidgetdataType?.titlePosition==="HDN"){
  setSelectedDisplayOption(2)
}
  },[])
  React.useEffect(()=>{

    if(props?.caresoulWidgetdataType?.contentIndicator==="TOP"){
      setSelectedIndicatorPosition(0)
    }
    else if(props?.caresoulWidgetdataType?.contentIndicator==="BOT"){setSelectedIndicatorPosition(1)}
else if(props?.caresoulWidgetdataType?.contentIndicator==="HDN"){
  setSelectedIndicatorPosition(2)
}

  },[])
  React.useEffect(()=>{
    if(props?.caresoulWidgetdataType?.contentIndicator==="TOP"){
      setSelectedIndicatorPosition(0)
    }
    else if(props?.caresoulWidgetdataType?.contentIndicator==="MDL"){setSelectedIndicatorPosition(1)}
else if(props?.caresoulWidgetdataType?.contentIndicator==="HDN"){
  setSelectedIndicatorPosition(2)
}
  },[]);
  //onChange Function
  const getCaroslWidgetTitle = (event) => {
    const result = {
      ...props.caresoulWidgetdataType,
      title: event.target.value,
    };
    props.setCaresoulWidgetDateType(result);
  };

  const selectTitleDisplayOption = (title) => {
    if (title === "top") {
      setSelectedDisplayOption(0);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        titlePosition: "TOP",
      });
    } else if (title === "middle") {
      setSelectedDisplayOption(1);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        titlePosition: "MDL",
      });
    } else if (title === "hidden") {
      setSelectedDisplayOption(2);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        titlePosition: "HDN",
      });
    }
  };

  const selectTitleDisplayPosition = (title) => {
    if (title === "top") {
      setSelectedIndicatorPosition(0);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        contentIndicator: "TOP",
      });
    } else if (title === "bottom") {
      setSelectedIndicatorPosition(1);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        contentIndicator: "BOT",
      });
    } else if (title === "hidden") {
      setSelectedIndicatorPosition(2);
      props.setCaresoulWidgetDateType({
        ...props.caresoulWidgetdataType,
        contentIndicator: "HDN",
      });
    }
  };

  //Function needs value as param because it,s for AutoComplete which opens DropDown
  const getNumberOfItemInCrousel = (event, value) => {
    const numberOfItems = parseInt(value);
    props.setCaresoulWidgetDateType({
      ...props.caresoulWidgetdataType,
      listingLayoutContentSize: numberOfItems,
    });
  };

  const enableAutoRotateCarousel = (event) => {
    props.setCaresoulWidgetDateType({
      ...props.caresoulWidgetdataType,
      autoRotate: event.target.checked,
    });
  };

  const getCarosalAutoRotateDuration = (event) => {
   // const duration = parseInt(event.target.value);
   setAutoSlideDuration(event.target.value)
    props.setCaresoulWidgetDateType({
      ...props.caresoulWidgetdataType,
      autoRotateDuration:parseInt(event.target.value),
    });
    
  };
  /* for multilinguil testfield  
 
 const getTitleNameEnUs = (event) => {};
  const enableMultilingualTitle = (event) => {
    props.setCaresoulWidgetDateType({
      ...props.caresoulWidgetdataType,
      enableMultilingualTitle: event.target.checked,
    });
  }; */

  var selectedTopTitle;
  var selectedMiddleTitle;
  var selectedHiddenTitle;
  var selectedTopIndicatorPostionTitle;
  var selectedMiddleIndicatorPostionTitle;
  var selectedHiddenIndicatorPostionTitle;
  if (selectedDisplayOption === 1) {
    selectedMiddleTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (selectedDisplayOption === 0) {
    selectedTopTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (selectedDisplayOption === 2) {
    selectedHiddenTitle = classNames({
      ["  " + classes.selectedType]: true,
    });
  }

  if (selectedIndicatorPosition === 1) {
    selectedMiddleIndicatorPostionTitle = classNames({
      ["  " + classes.indicatorPositionselectedType]: true,
    });
  } else if (selectedIndicatorPosition === 0) {
    selectedTopIndicatorPostionTitle = classNames({
      ["  " + classes.indicatorPositionselectedType]: true,
    });
  } else if (selectedIndicatorPosition === 2) {
    selectedHiddenIndicatorPostionTitle = classNames({
      ["  " + classes.indicatorPositionselectedType]: true,
    });
  }

  const enableSchedule = (event) => {
    props.setWidgetCommonDetails({
      ...props.widgetCommonDetails,
      enableSchedule: event.target.checked,
    });
  };
  return (
    <div>
      {/* <Typography style={{}}>Title (Optional)</Typography> */}
      {/* <div className={classes.widgetDetailContainer}>
        {" "}
        <TextField
          variant="outlined"
          placeholder="Enter Title"
          size="small"
          value={props.caresoulWidgetdataType?.title}
          className={classes.textField}
          onChange={getCaroslWidgetTitle}
        />
      </div> */}
      <div style={{display:"flex"}}>
        <div>
          <Typography style={{}}>Title Display Option</Typography>
          <div
            className={classes.widgetDetailContainer}
            style={{
              width: "550px",
              gap: "1rem"
            }}
          >
            {" "}
            <div
              className={classNames(
                classes.displayOptionContainer,
                selectedTopTitle
              )}
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                selectTitleDisplayOption("top");
              }}
            >
              <Typography>Top Title</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={classNames(
                classes.displayOptionContainer,
                selectedMiddleTitle
              )}
              onClick={() => {
                selectTitleDisplayOption("middle");
              }}
            >
              <Typography>Middle Title</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={classNames(
                classes.displayOptionContainer,
                selectedHiddenTitle
              )}
              onClick={() => {
                selectTitleDisplayOption("hidden");
              }}
            >
              <Typography>Hidden Title</Typography>
            </div>
          </div>
        </div>
        <div>
          <Typography style={{}}>Indicator Position</Typography>
          <div
            className={classes.widgetDetailContainer}
            style={{
              width: "660px",
              gap: "1rem"
            }}
          >
            {" "}
            <div
              className={classNames(
                classes.indicatorPositionContainer,
                selectedTopIndicatorPostionTitle
              )}
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                selectTitleDisplayPosition("top");
              }}
            >
              <Typography>Top Indicator</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={classNames(
                classes.indicatorPositionContainer,
                selectedMiddleIndicatorPostionTitle
              )}
              onClick={() => {
                selectTitleDisplayPosition("bottom");
              }}
            >
              <Typography>Bottom Indicator</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={classNames(
                classes.indicatorPositionContainer,
                selectedHiddenIndicatorPostionTitle
              )}
              onClick={() => {
                selectTitleDisplayPosition("hidden");
              }}
            >
              <Typography>Hidden Indicator</Typography>
            </div>
          </div>
        </div>
      </div>
     
      <div style={{display:"flex" ,alignItems:"center",gap:"1rem"}}>
        {/* <Typography style={{}}>Number Of Items in the Carousel*</Typography> */}
        <div className={classes.widgetDetailContainer} style={{display:"flex",flexDirection:"column",marginTop:"10px"}}>
        <Typography style={{marginBottom:"10px"}}>Number Of Items in the Carousel*</Typography>
          {/*
          <TextField
            placeholder="Enter Carousel Auto Rotate Duration"
            size="medium"
            variant="outlined"
            className={classes.textField}
            style={{}}
            onChange={getNumberOfItemInCrousel}
          />*/}
          <Box sx={{my:2}} className={classes.validateBox} style={{margin: "0px"}}>
         
            {/* <Autocomplete
              options={numberOfItem}
              getOptionLabel={(option) => option}
              // style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  size="medium"
                  className={classes.textField}
                  {...params}
                  placeholder="Select the number of item in Carousel *"
                  variant="outlined"
                  label={props.caresoulWidgetdataType?.listingLayoutContentSize?props.caresoulWidgetdataType?.listingLayoutContentSize:""}
                />
              )}
              onChange={getNumberOfItemInCrousel}
            /> */}
             <VivreAutocomplete
              options={numberOfItem}
              optionListStyle = {{width:"412px"}}
              getOptionLabel={(option) => option}
              defaultValue={props.caresoulWidgetdataType?.listingLayoutContentSize?props.caresoulWidgetdataType?.listingLayoutContentSize:""}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select the number of item in Carousel *"
                  variant="outlined"
                  label={"Number Of Items in the Carousel*"}
                  style={{width:"280px"}}
                />
              )}
              onChange={getNumberOfItemInCrousel}
            />
            {props.openMandatoryWarn ? (
              props.caresoulWidgetdataType?.listingLayoutContentSize ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
        </div>
        <div>
        <Typography style={{}}>Select PlayList *</Typography>
        <Box className={classes.validateBox}>
          <Box className={classes.playListBrowseContainer} style={{}}>
            <Box> {props.playListId ?  `${props.playListId}` + `${" "}` : "PlayList*"} {props.playlistName ? `${props.playlistName}` : ""}</Box>
            <Box
              className={classes.playlistBrowseButton}
              onClick={() => {
                props.setOpenModal(!props.openModal);
              }}
            >
              Browse
            </Box>
          </Box>
          {props.openMandatoryWarn ? (
            props.playListId ? null : (
              <span className={classes.validationText}>* Mandatory Field</span>
            )
          ) : null}
        </Box>
      </div>


      </div>
      <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        {" "}
        <Typography style={{}}>Auto Slide Carousel</Typography>{" "}
        <AntSwitch defaultChecked={props.caresoulWidgetdataType?.autoRotate} name="checkedC" onChange={enableAutoRotateCarousel} />
      </div>
      <div>
        <Typography style={{}}>Carousel Auto Slide Duration</Typography>
        <div className={classes.widgetDetailContainer}>
          {" "}
          <Box className={classes.validateBox}>
            <TextField
            value={props.caresoulWidgetdataType?.autoRotate?autoSlideDuration:""}

              disabled={!props.caresoulWidgetdataType?.autoRotate}
              placeholder="Enter Carousel Auto Slide Duration(Seconds)"
              size="small"
              variant="outlined"
              className={classes.textField}
              onChange={getCarosalAutoRotateDuration}
            />
            {props.openMandatoryWarn ? (
              props.caresoulWidgetdataType.autoRotate ? (
                props.caresoulWidgetdataType?.autoRotateDuration ? null : (
                  <span className={classes.validationText}>
                    * Mandatory Field
                  </span>
                )
              ) : null
            ) : null}
          </Box>
        </div>
      </div>
     
      {/*    <div
        className={classes.widgetDetailContainer}
        style={{
        
          width: "300px",
          
        }}
      >
        {" "}
        <Typography style={{}}>Enable Multilingual title</Typography>{" "}
        <AntSwitch name="checkedC" onChange={enableMultilingualTitle} />
      </div>
      <div >
        {" "}
        <Typography style={{}}>Title Name (En-Us)</Typography>
       <div  className={classes.widgetDetailContainer}> <TextField
         
          placeholder='Enter Title Name(En-Us)'
          size="medium"
          variant="outlined"
          className={classes.textField}
          style={{ }}
          onChange={getTitleNameEnUs}
        /></div>
      </div> */}
      <div
        className={classes.widgetDetailContainer}
        style={{
          width: "300px",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        {" "}
        <Typography style={{}}>Enable Schedule</Typography>{" "}
        <AntSwitch key={props?.widgetCommonDetails?.enableSchedule+"Enable Schedule"} name="checkedC" onChange={enableSchedule} defaultChecked={(props?.widgetCommonDetails?.enableSchedule)} />
      </div>
    </div>
  );
};

export default CaroslType;
