import axios from "axios";

axios.interceptors.response.use(
  (res) => res,
  async (error) => {

    if (localStorage.getItem("token")) {
      const originalRequest = error.config;

      if ((error.response?.status === 401 || error.response?.status === 403)&& !originalRequest._retry) {
        originalRequest._retry = true;

        const refresh = await axios.get(
          `${process.env.REACT_APP_AUTH_BASE_URL}${process.env.REACT_APP_ENV}/v1/refresh-token`,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY,
              Authorization: localStorage.getItem("refreshToken"),
            },
          }
        );

        if (refresh?.data?.hzStatus == 200) {
          localStorage.setItem("token", refresh.data.jwtToken);
          localStorage.setItem("refreshToken", refresh?.data?.refreshToken);

          originalRequest.headers["Authorization"] = refresh.data.jwtToken;
          return axios(originalRequest);
        } else {
          localStorage.clear()
          window.location.href = '/login';
          return { message: refresh?.response?.data };
        }
      } else {
        return error.response.data;
      }
    }
  }
);
