import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getAllLanguageData,
  getAllProjectsData,
  getCMSConfigsuccess,
  getFeatureConfigData,
} from "../actions/projectActions";
import { getAllProjectsApi, getAllLanguageApi, getCMSConfigApi, getFeatureConfigApi } from "../apis/projectApis";

export function* watchGetAllProjects() {
  yield takeEvery("GET_ALL_PROJECTS_ACTION", handleGetAllProjects);
  yield takeEvery("GET_ALL_LANGUAGE", handleGetAllLanguage);
  yield takeLatest("GET_CMS_CONFIG", handleGetCMSConfig);
  yield takeLatest("GET_FEATURECONFIG_ACTION", handleGetFeatureConfig);
}

function* handleGetAllProjects() {
  const allProjects = yield call(handleProjects);
  yield put(getAllProjectsData(allProjects));
}

function* handleGetFeatureConfig(payload) {
  const data = yield call(handleFeatureConfig,payload.payload);
  console.log(data)
  yield put(getFeatureConfigData(data));
}


function* handleGetAllLanguage() {
  const allLanguage = yield call(handleLanguage);
  yield put(getAllLanguageData(allLanguage));
}

function* handleGetCMSConfig({ payload }) {
  const cmsDetails = yield call(handleCMSConfig, payload);
  yield put(getCMSConfigsuccess(cmsDetails));
}

const handleProjects = async () => {
  try {
    const res = await axios.get(getAllProjectsApi, {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

const handleLanguage = async () => {
  try {
    const res = await axios.get(getAllLanguageApi, {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    });
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

const handleCMSConfig = async (pid) => {
  try {
    const res = await axios.get(getCMSConfigApi, {
      params: { p_id: pid },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const handleFeatureConfig = async (t_id) => {

  try {
    const res = await axios.get(getFeatureConfigApi, {
      params: { t_id: t_id },
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY_3,
      },
    });
    if(res){
      return  {featureConfig:res?.data?.data?.featureConfig, maintenaceSchedule:res?.data?.data?.maintenaceSchedule}
    }
  } catch (err) {
    console.log(err);
  }
};


