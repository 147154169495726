import React from "react";
import { Box, TextField } from "@material-ui/core";
import VivreAutocomplete from '../Autocomplete/Autocomplete';

const AspectRatio = ({
  classes,
  projectAspectRatio,
  bannerSelected,
  getImageDimensions,
  openMandatoryWarn,
  imageRatio
}) => {
  const handleImageDimensions = (event, dimension, index) => {
    var ratio = dimension?.trim()?.replace(/\D/g, "/");
    var imageCal = eval(ratio);
    if (imageCal <= 1.5 && imageCal > 1) {
      getImageDimensions(dimension, 144, 96, 3, index);
    } else if (imageCal > 1.5) {
      getImageDimensions(dimension, 170, 96, 2, index);
    } else if (imageCal > 0.5 && imageCal < 1) {
      getImageDimensions(dimension, 64, 96, 6, index);
    } else if (imageCal === 1) {
      getImageDimensions(dimension, 96, 96, 4, index);
    }
  };

  return (
    <div className={classes.widgetDetailContainer}>
      <Box sx={{ pt: 2 }}>
        <VivreAutocomplete
          defaultValue={typeof bannerSelected === "number" ? projectAspectRatio?.[bannerSelected] : ""}
          options={projectAspectRatio}
          optionListStyle={{ width: "330px" }}
          getOptionLabel={(option) => option}
          renderInput={(props) => (
            <TextField
              {...props}
              size="small"
              className={classes.textField}
              placeholder="Select Banner Layout*"
              variant="outlined"
              label="Banner Layout"
              style={{
                width: "230px",
                background: "white",
                height: "40px",
                borderRadius: "5px",
                marginBottom: openMandatoryWarn && !imageRatio ? "2px" : "0",
              }}
            />
          )}
          onChange={handleImageDimensions}
        />
        {openMandatoryWarn && !imageRatio && (
          <span className={classes.validationText} style={{ color: "red", marginTop: "-5px" }}>
            * Mandatory Field
          </span>
        )}
      </Box>
    </div>
  );
};

export default AspectRatio;

